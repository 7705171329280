.navbar-vertical {
	background: linear-gradient(
		to top left,
		#e00253,
		#830d76,
		#6f0463
	) !important;
}

.bg-color-brand {
	background: linear-gradient(
		to top left,
		#e00253,
		#830d76,
		#6f0463
	) !important;
}

.border-color-brand {
	border: 1px solid #830d76;
}

.text-color-brand {
	color: #830d76 !important;
}

.nav-link {
	color: #fff !important;
}

.nav-link .active {
	background: #fff !important;
}

.card-dash {
	background: linear-gradient(
		to top left,
		#e00253,
		#830d76,
		#6f0463
	) !important;

	color: #fff;
}
